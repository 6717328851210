import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '../_services';
import { BracketService } from './bracket-service.service';

import austin from '../../assets/2019_data/austin_2019.json';
import chicago from '../../assets/2019_data/chicago_2019.json';
import hawaii from '../../assets/2019_data/hawaii_2019.json';
import hermosa from '../../assets/2019_data/hermosa_2019.json';
import huntington from '../../assets/2019_data/huntington_2019.json';
import manhattan from '../../assets/2019_data/manhattan_2019.json';
import new_york from '../../assets/2019_data/new_york_2019.json';
import seattle from '../../assets/2019_data/seattle_2019.json';
import { Router } from '@angular/router';

export class Match {
	public MatchNo: number;
	public seed_picked: number;

}

@Component({
	selector: 'app-brackets',
	templateUrl: './brackets.component.html',
	styleUrls: ['./brackets.component.scss']
})
export class BracketsComponent implements OnInit {
	headerHeader: string;
	headerText: string;
	loaded: boolean;
	winners: boolean;

	newRound1: Array<any>;

	Round1: Array<any>;
	Round2: Array<any>;
	Round3: Array<any>;
	Round4: Array<any>;
	Round5: Array<any>;
	//not used
	brackets: Array<any>;
	//

	FirstPlacePick: string;
	SecondPlacePick: string;
	ThirdPlacePickA: string;
	ThirdPlacePickB: string;

	testBracket: Array<any> = [];

	//use this to store each round 
	//depending on the bracket size depends on how many rounds are loaded
	//
	newBracket: Array<any> = [];

	bracketPicks: Array<any>[];


	userMatchPicks: Match[] = [];


	constructor(private authenticationService: AuthenticationService,
		private _snackBar: MatSnackBar, private bracketService: BracketService, private router: Router, ) {


			//this.selectTournament('huntington');

		this.loaded = false;
		this.headerHeader = '';
		this.headerText = '';

		
		
		

	}


	selectTournament(sortField: any) {
		
		this.bracketService.getBracket(sortField.value);
		this.bracketService.resetBracket();
		this.bracketService.setWinnersBracket();

			//reset user picks
		this.resetPicks();
		this.newBracket = this.bracketService.city_bracket;
		this.testBracket = this.bracketService.live_bracket;

		this.winners = true;
		this.headerHeader = sortField.value;

		console.log(this.testBracket);
	}
	selectBracket(bracketField: any) {
		if (bracketField.value == 'winners') {
			this.bracketService.live_bracket = [];
			this.winners = true;
			this.bracketService.setWinnersBracket();
		} else {
			this.bracketService.live_bracket = [];
			this.winners = false;
			this.bracketService.setContendersBracket();
		}
		if (this.bracketService.live_bracket.length > 0) {
			this.testBracket = [];
			this.testBracket = this.bracketService.live_bracket;
		}


	}
	selectGender(genderField: any) {

	}
	//use this to find the match to update by match number
	findIndexToUpdate(newItem) {
		return newItem.MatchNo === this;
	}
	goTo(matchNo, teamChoosen: any, notTeamChoosen: any) {

		//This works this finds the match to update the team that they choose
		//set user picks
		this.userMatchPicks.find((val) => {
			return val.MatchNo == matchNo;
		}).seed_picked = teamChoosen.Seed;

		if (this.winners == true) {
			//get the round that the user has selected //current winners round
			var currentRound = this.bracketService.bracketProperties[0].winners[this.bracketService.bracket_size.toString()]["tournamentNumbers"];
			//use the current round to find out what the team the user selected should be put into 		
			console.log('current Round',currentRound);
			console.log('currentRound.indexOf(matchNo)',currentRound.indexOf(matchNo));
			var selected_team_to_next_match_no = this.bracketService.bracketProperties[0].winners[this.bracketService.bracket_size.toString()]["tournamentMovementWinnersTo"][currentRound.indexOf(matchNo)];
			console.log('selected_team_to_next_match_no',selected_team_to_next_match_no);
			//parse the number out of a or b which item to update on the city bracket
			let updateItem = this.newBracket.find(this.findIndexToUpdate, +selected_team_to_next_match_no.split('_')[0]);
			
			//get the index number of the city bracket to update
			let index = this.newBracket.indexOf(updateItem);
			//should the team the user selected go to the a match or the b match
			//this ensures every team selcted has a slot to be placed in
			if (selected_team_to_next_match_no.split('_')[1] == 'a') {
				this.newBracket[index].TeamA = teamChoosen;
			} else if (selected_team_to_next_match_no.split('_')[1] == 'b') {
				this.newBracket[index].TeamB = teamChoosen;
			} 
			//Set what match no the losers go to 
			//go through the same process as above
			var non_selected_team_to_next_match_no = this.bracketService.bracketProperties[0].winners[this.bracketService.bracket_size.toString()]["tournamentMovementLoserTo"][currentRound.indexOf(matchNo)];
			console.log('non_selected_team_to_next_match_no',non_selected_team_to_next_match_no);
			let updateLoser = this.newBracket.find(this.findIndexToUpdate, +non_selected_team_to_next_match_no.split('_')[0]);
			let indexLoser = this.newBracket.indexOf(updateLoser);
			//set the non selected a or b match no to go to 
			if (non_selected_team_to_next_match_no.split('_')[0] > 0) {
				if (non_selected_team_to_next_match_no.split('_')[1] == 'a') {
					this.newBracket[indexLoser].TeamA = notTeamChoosen;
				} else if (non_selected_team_to_next_match_no.split('_')[1] == 'b') {
					this.newBracket[indexLoser].TeamB = notTeamChoosen;
				}
			} 
			//  NOT SURE IF NEED TO REFRESH EVERY TIME
			//Find matches update team a or b 
			//this.testBracket =[];
			//  this.getWinnersBracket();

		} else {
			//if in the contenders round got to next round match
			var currentRound = this.bracketService.bracketProperties[0].contenders[this.bracketService.bracket_size.toString()]["tournamentNumbers"];
			//use the current round to find out what the team the user selected should be put into 		
			var selected_team_to_next_match_no = this.bracketService.bracketProperties[0].contenders[this.bracketService.bracket_size.toString()]["tournamentMovementWinnersTo"][currentRound.indexOf(matchNo)];
			console.log('selected_team_to_next_match_no',selected_team_to_next_match_no);
			//parse the number out of a or b which item to update on the city bracket
			let updateItem = this.newBracket.find(this.findIndexToUpdate, +selected_team_to_next_match_no.split('_')[0]);
			

			let index = this.newBracket.indexOf(updateItem);


			if (selected_team_to_next_match_no.split('_')[0] > 0) {
				if (selected_team_to_next_match_no.split('_')[1] == 'a') {
				this.newBracket[index].TeamA = teamChoosen;
				} else if (selected_team_to_next_match_no.split('_')[1] == 'b') {
					this.newBracket[index].TeamB = teamChoosen;
				}
			}

		}


		//Do this later add winning matches to the display
		/* if (matchNo == 27) {
			this.ThirdPlacePickA = '(' + notTeamChoosen.Seed + ')' + notTeamChoosen.Captain.LastName + '/' + notTeamChoosen.Player.LastName;
		}
		if (matchNo == 28) {
			this.ThirdPlacePickB = '(' + notTeamChoosen.Seed + ')' + notTeamChoosen.Captain.LastName + '/' + notTeamChoosen.Player.LastName;
		}
		if (matchNo == 29) {
			this.FirstPlacePick = '(' + teamChoosen.Seed + ')' + teamChoosen.Captain.LastName + '/' + teamChoosen.Player.LastName;
			this.SecondPlacePick = '(' + notTeamChoosen.Seed + ')' + notTeamChoosen.Captain.LastName + '/' + notTeamChoosen.Player.LastName;
		} */

		//get the teams choosen from the new bracket that the user picked
		//third place = losers from match 27 & 28


	}

	selectionHandler(e) {

		this.goTo(e.matchNo, e.teamChoosen, e.teamNotChoosen);

	}

	resetPicks() {
		this.userMatchPicks = [];
		const i = 65;
		for (var a = 1; a <= i; a++) {
			var match = new Match;
			match.MatchNo = a;
			match.seed_picked = 0;
			this.userMatchPicks.push(match);
		}
	}

	

	voteHandler(e) {
		for (let column of this.brackets) {
			for (let bracket of column) {
				if (bracket.match == e.value.match) {
					bracket.voted = e.teamID;
					console.log(bracket);
				}
			}
		}

	}


	ngOnInit() {
		console.log('in oninit');
	}


	//to get the bracket size of each tournament
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/22 - austin  ds=16
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/26 - seattle ds=16
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/32 - chicago ds=24
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/34 - hawaii  ds=16 
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/28 - hermosa ds=24
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/20 - huntington ds=24
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/30 - manhattan ds=32
	//https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/24 - new york ds=16
	//Create a component for each bracket size

	/* bracketProperties: Array<any> = [{
		"winners": {
			"16": {
				"tournamentNumbers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 27, 28, 29],
				"tournamentMovementLoserTo": ['18_a', '18_b', '17_a', '17_b', '16_a', '16_b', '15_a', '15_b', '20_a', '19_a', '22_a', '21_a', '26_a', '25_a', '0_a', '0_a', '0_a'],
				"tournamentMovementWinnersTo": ['9_a', '9_b', '10_a', '10_b', '11_a', '11_b', '12_a', '12_b', '13_a', '13_b', '14_a', '14_b', '27_a', '28_a', '29_a', '29_b', '1'],

				"tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25, 26, 0],

				"columnBreakIndex": [7, 11, 13, 15, 16],
				"round1": [1, 2, 3, 4, 5, 6, 7, 8],
				"round2": [9, 10, 11, 12],
				"round3": [13, 14],
				"round4": [27, 28],
				"round5": [29]
			},
			"24": {
				"tournamentNumbers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 43, 44, 45],
				"tournamentMovementLoserTo": ['23_a', '24_a', '25_a', '26_a', '27_a', '28_a', '29_a', '30_a', '30_b', '29_b', '28_b', '27_b', '26_b', '25_b', '24_b', '23_b', '36_a', '35_a', '38_a', '37_a', '42_a', '41_a', '3', '3', '2'],
				"tournamentMovementWinnersTo": ['9_b', '10_b', '11_b', '12_b', '13_b', '14_b', '15_b', '16_b', '17_a', '17_b', '18_a', '18_b', '19_a', '19_b', '20_a', '20_b', '21_a', '21_b', '22_a', '22_b', '43_a', '44_a', '45_a', '45_b', '1'],
				"tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 42, 0],

				"columnBreakIndex": [7, 15, 19, 21, 23, 24],
				"round1": [1, 2, 3, 4, 5, 6, 7, 8],
				"round2": [9, 10, 11, 12, 13, 14, 15, 16],
				"round3": [17, 18, 19, 20],
				"round4": [21, 22],
				"round5": [43, 44],
				"round6": [45]
			},
			"32": {
				"tournamentNumbers": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 59, 60, 61],
				"tournamentMovementWinnersTo": ['17_a', '17_b', '18_a', '18_b', '19_a', '19_b', '20_a', '20_b', '21_a', '21_b', '22_a', '22_b', '23_a', '23_b', '24_a', '24_b', '25_a', '25_b', '26_a', '26_b', '27_a', '27_b', '28_a', '28_b', '29_a', '29_b', '30_a', '30_b', '59_a', '60_a', '61_a', '61_b', '1'],
				"tournamentMovementLoserTo": ['31_a', '31_b', '32_a', '32_b', '33_a', '33_b', '34_a', '34_b', '35_a', '35_b', '36_a', '36_b', '37_a', '37_b', '38_a', '38_b', '46_a', '45_a', '44_a', '43_a', '42_a', '41_a', '40_a', '39_a', '52_a', '51_a', '54_a', '53_a', '58_a', '57_a', '57_b', '58_a', 0],

				"tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 57, 58, 0],

				"columnBreakIndex": [15, 23, 27, 29, 31, 32],
				"round1": [9, 10, 11, 12, 13, 14, 15, 16],
				"round2": [17, 18, 19, 20, 21, 22, 23, 24],
				"round3": [25, 26, 27, 28],
				"round4": [29, 30],
				"round5": [59, 60],
				"round6": [61]
			}

		},
		"contenders": {
			"16": {
				"tournamentNumbers": [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
				"tournamentMovementWinnersTo": ['19_b', '20_b', '21_b', '22_b', '23_a', '23_b', '24_a', '24_b', '25_b', '26_b', '27_b', '28_b'],
				"tournamentMovementFrom": [0, 0, 0, 0, 10, 9, 12, 11, 0, 0, 14, 13],
				"columnBreakIndex": [3, 7, 9, 11],
				"round1": [15, 16, 17, 18],
				"round2": [19, 20, 21, 22],
				"round3": [23, 24],
				"round4": [25, 26]
			},
			"24": {
				"tournamentNumbers": [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
				"tournamentMovementWinnersTo": ['31_a', '31_b', '32_a', '32_b', '33_a', '33_b', '34_a', '34_b', '35_b', '36_b', '37_b', '38_b', '39_a', '39_b', '40_a', '40_b', '41_b', '42_b', '43_b', '44_b'],
				"tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 18, 17, 20, 19, 0, 0, 22, 21],
				"columnBreakIndex": [7, 11, 15, 17, 19],
				"round1": [23, 24, 25, 26, 27, 28, 29, 30],
				"round2": [31, 32, 33, 34],
				"round3": [35, 36, 37, 38],
				"round4": [39, 40],
				"round5": [41, 42]
			},
			"32": {
				"tournamentNumbers": [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58],
				"tournamentMovementWinnersTo": ['39_b', '40_b', '41_b', '42_b', '43_b', '44_b', '45_b', '46_b', '47_a', '47_b', '48_a', '48_b', '49_a', '49_b', '50_a', '50_b', '51_b', '52_b', '53_b', '54_b', '55_a', '55_b', '56_a', '56_b', '57_b', '58_b', '59_b', '60_b'],
				"tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 24, 23, 22, 21, 20, 19, 18, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 29],
				"columnBreakIndex": [7, 15, 19, 23, 25, 27],
				"round1": [31, 32, 33, 34, 35, 36, 37, 38],
				"round2": [39, 40, 41, 42, 43, 44, 45, 46],
				"round3": [47, 48, 49, 50],
				"round4": [51, 52, 53, 54],
				"round5": [55, 56],
				"round6": [57, 58]
			}
		}
	}]; */

}
