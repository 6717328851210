import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BracketDetailsDialogComponent } from '../bracket-details-dialog/bracket-details-dialog.component';

@Component({
  selector: 'app-bracket-details',
  templateUrl: './bracket-details.component.html',
  styleUrls: ['./bracket-details.component.scss']
})
export class BracketDetailsComponent implements OnInit {

  @Input() value: any;
	@Output() voteBracketEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() chooseTeamEvent:EventEmitter<any> = new EventEmitter<any>();
  
  
  constructor(public dialog: MatDialog) { }

  voteForTeam(teamID) {
		this.voteBracketEvent.emit({value: this.value, teamID: teamID});
	}

	chooseTeam(matchNo,team,teamNot){
		
		this.chooseTeamEvent.emit({matchNo: matchNo, teamChoosen: team,teamNotChoosen:teamNot});
  }
  
  openDialog(e, bracketsTeam) {
  
		e.preventDefault();
		this.dialog.open(BracketDetailsDialogComponent, {
			data: bracketsTeam,
			maxHeight: '90vw',
			maxWidth: '90vw'
		});
	}
  ngOnInit() {
  }

}
