import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentUrlService} from '../_shared/services/environment-url.service';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';

import {TournamentResponse} from '../_models/tournament.model';



@Injectable({
  providedIn: 'root'
})
export class TournamentService {

  tournaments: Observable<TournamentResponse[]>;

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {
  }

  public getAllData():Observable<TournamentResponse[]> {
    if(!this.tournaments) {
      console.log('LeaderBoard not cached');
      this.tournaments =  this.http.get(this.createCompleteRoute(`api/event/all`, this.envUrl.urlAddress))
        .pipe(map(data => data as TournamentResponse[]),
          publishReplay(1),
          refCount()
        );
    }

    return this.tournaments;
  }

  //This will be a new API to get the current tournament only 
  public getCurrentTournamentData():Observable<TournamentResponse[]> {
    if(!this.tournaments) {
      console.log('LeaderBoard not cached');
      this.tournaments =  this.http.get(this.createCompleteRoute(`api/event/all`, this.envUrl.urlAddress))
        .pipe(map(data => data as TournamentResponse[]),
          publishReplay(1),
          refCount()
        );
    }

    return this.tournaments;
  }


  public clearCache(){
    this.tournaments=null;
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }
  private generateHeaders() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }
}
