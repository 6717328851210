import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { TournamentComponent } from './tournament/tournament.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResearchDetailsComponent } from './research-details/research-details.component';
import { ResearchComponent } from './research/research.component';
import { DraftComponent } from './draft/draft.component';
import { LeaderBoardComponent } from './leader-board/leader-board.component';
import {LoginGuardGuard} from './_guards/login-guard.guard';
import {FantasyTeamComponent} from './fantasy-team/fantasy-team.component';
import {PickEmComponent} from './pick-em/pick-em.component';
import {SixPackComponent} from './six-pack/six-pack.component';
import { BracketsComponent } from './brackets/brackets.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'tournament', component: TournamentComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'research', component: ResearchComponent,canActivate: [ LoginGuardGuard ] },
  { path: 'research-details', component: ResearchDetailsComponent,canActivate: [ LoginGuardGuard ] },
  { path: 'draft', component: DraftComponent,canActivate: [ LoginGuardGuard ]  },
  { path: 'leader-board', component: LeaderBoardComponent ,canActivate: [ LoginGuardGuard ]},
  { path: 'fantasy-team', component: FantasyTeamComponent ,canActivate: [ LoginGuardGuard ]},
  { path: 'pick-em', component: PickEmComponent ,canActivate: [ LoginGuardGuard ]},
  { path: 'six-pack', component: SixPackComponent ,canActivate: [ LoginGuardGuard ]},
  { path: 'bracket', component: BracketsComponent, canActivate: [LoginGuardGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
