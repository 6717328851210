import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-terms-of-use',
	templateUrl: './terms-of-use.component.html',
	styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

	headerHeader: string;
	headerText: string;

	constructor() {
		this.headerHeader = '<strong>Terms &amp; <span>Conditions</span></strong>';
		this.headerText = 'Morbi at erat vitae libero dictum vehicula. Sed ex neque, eleifend <a href="#">non pharetra</a> et, sodales ac metus. Aliquam ac orci sit amet diam euismod porttitor sed sed dolor. ';
	}

	ngOnInit() {
	}

}
