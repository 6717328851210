import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { TournamentResponse } from '../_models/tournament.model';

import { ErrorHandlerService } from '../_shared/services/error-handler.service';
import { NavigationExtras, Router } from '@angular/router';
import { TournamentService } from '../_services/tournament.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	events: TournamentResponse[];
	event: TournamentResponse;
	errorMessage: string;

	headerImage: string;
	headerHeader: string;
	headerText: string;
	loggedin = false;

	constructor(private auth: AuthenticationService,
		private router: Router,
		private service: TournamentService,
		private errorHandler: ErrorHandlerService) {
		this.headerImage = '';
		this.headerHeader = 'AVP Beach <span>VolleyBall</span> Fantasy League';
		
		service.clearCache();
		this.loadData();
	}

	ngOnInit() {
		// redirect to home if already logged in
		if (this.auth.currentUserValue) {
			this.loggedin = true;
			this.loadData();
		}
	}
	//Get the most recent live tournament
	//this will go into the load of the event.
	//we should set a cookie on the persons browser so that the most recent event is always showing
	//README
	async loadData() {
		this.service.getCurrentTournamentData().subscribe(data => {
			this.event = data.filter((value => {
			  return (value.scheduleNo === 3);
			}))[0];
		  },
			(error) => {
				this.errorHandler.handleError(error);
				this.errorMessage = this.errorHandler.errorMessage;
				console.error('ERROR loadData() TOURNAMENT Page', this.errorMessage);
			});
	}


	open(game:any) {
console.log(game);
		//https://alligator.io/angular/query-parameters/
		//Check the status of the tournament.
		//Also check the button that was selected for the user
		//
		if(game === 'Fantasy'){
		this.router.navigate(['/draft'],
			{queryParams:
			{ dateRange: this.event.dateRange,
			  location: this.event.location,
			  status: this.event.status,
			  scheduleNo: this.event.scheduleNo,
			  statusName: this.event.statusName,
			  eventName: this.event.eventName

			}});
		}
		if(game==='Pickem'){
			this.router.navigate(['/pick-em'],
			{queryParams:
			{ dateRange: this.event.dateRange,
			  location: this.event.location,
			  status: this.event.status,
			  scheduleNo: this.event.scheduleNo,
			  statusName: this.event.statusName,
			  eventName: this.event.eventName

			}});

		}
		if(game==='Bracket'){
			this.router.navigate(['/bracket'],
			{queryParams:
			{ dateRange: this.event.dateRange,
			  location: this.event.location,
			  status: this.event.status,
			  scheduleNo: this.event.scheduleNo,
			  statusName: this.event.statusName,
			  eventName: this.event.eventName

			}});

		}
}

	trackByFn(index, item) {
		return item.scheduleNo; // unique id corresponding to the item
	  }
}
