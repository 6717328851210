import { Component, OnInit } from '@angular/core';

import {MatSnackBar} from '@angular/material';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-six-pack',
  templateUrl: './six-pack.component.html',
  styleUrls: ['./six-pack.component.scss']
})
export class SixPackComponent implements OnInit {
  headerHeader: string;
  headerText: string;
  one=[];
  two=[];
  three=[];
  four=[];
  five=[];
  six=[];
  all =[];
  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep'
  ];

  done = [
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];
  timePeriods = [
    '1st ',
    '2nd ',
    '3rd ',
    '4th ',
    '5th ',
    '6th ',
    '7th ',
    '11st ',
    '22nd ',
    '32rd ',
    '42th ',
    '52th ',
    '62th ',
    '72th '
  ];
  breakpoint: number;
  constructor(private _snackBar: MatSnackBar) {

this.headerHeader='Pick 6 Header';
this.headerText='Pick Six Text';

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  dropOne(event: CdkDragDrop<string[]>){
    if(this.one.length >0){
      console.log('do not put anything in here');
    }
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  dropTwo(event: CdkDragDrop<string[]>){
    if(this.two.length >0){
      console.log('do not put anything in here');
    }
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  dropThree(event: CdkDragDrop<string[]>){
    if(this.three.length >0){
      console.log('do not put anything in here');
    }
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  dropFour(event: CdkDragDrop<string[]>){
    if(this.four.length >0){
      console.log('do not put anything in here');
    }
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  dropFive(event: CdkDragDrop<string[]>){
    if(this.five.length >0){
      console.log('do not put anything in here');
    }
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  dropSix(event: CdkDragDrop<string[]>){
    if(this.six.length >0){
      console.log('do not put anything in here');
    }
    else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  dropOrder(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex);
  }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

}
