export interface PlayerTierResponse {
  eventNo: number;
  playerId: number;
  tier: number;
  seed: number;
  name: string;
  gender: string;
  imageUrl: string;
  partnerName: string;
  result: number;
  grandPointsTotal: number;
  statsPointTotal: number;
  seedMultiplier: number;
  matchesPlayed: number;
  attacks: number;
  kills: number;
  blocks: number;
  digs: number;
  aces: number;
  serviceErrors: number;
  lastUpdated: string;
  overAllFantasyPoints: number;
  matchesLost: number;
  tblocks:number;
  cblocks:number;
  setno:number;
}


export class Players {
  draftLink:string;
  color:string;
  seedMultiplier: number;
  grandPointsTotal: number;
  statsPointTotal: number;
  eventNo: number;
  playerId: number;
  tier: number;
  seed: number;
  name: string;
  gender: string;
  imageUrl: string;
  partnerName: string;
  result: number;
  matchesPlayed: number;
  attacks: number;
  kills: number;
  blocks: number;
  digs: number;
  aces: number;
  serviceErrors: number;
  lastUpdated: string;
  overAllFantasyPoints: number;
  matchesLost: number;
  isOnTeam:boolean;
  tblocks:number;
  cblocks:number;
  setno:number;
}

export class UserFantasyTeamRequest {
  playerId: number;
  userId: number;
  scheduleNo: number;
  tier: number;
}
export class UserFantasyTeamResponse {
  playerId: number;
  userId: number;
  scheduleNo: number;
  tier: number;
  name: string;
  imageUrl: string;
}
