
export class LoginRequest {
  public usernameOrEmail: string;
  public password: string;

}
export class SignUpRequest {

  name: string;
  username: string;

  email: string;

  password: string;
}
