import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentUrlService} from '../_shared/services/environment-url.service';
import {Observable} from 'rxjs';
import {PlayerMatchStatsResponse} from '../_models/player-research-details.model';
import {PlayerTierResponse} from '../_models/player-tier-response';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ResearchDetailsService {

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }
//
  getResearchTournamentPlayersLiveStats(eventNo: number): Observable<PlayerTierResponse[]> {
    let url = this.createCompleteRoute(`api/event/${eventNo}/players/live/stats`,this.envUrl.urlAddress);
    if (eventNo === 0) {
      url = this.createCompleteRoute(`api/event/player/stats/all`,this.envUrl.urlAddress);
    }

    return this.http.get(url).pipe(map(results => results as PlayerTierResponse[]));

  }
//
  // DONE
  getPlayerMatchStats(eventNo, playerId): Observable<PlayerMatchStatsResponse[]> {
    return this.http
      .get(this.createCompleteRoute(`api/event/${eventNo}/all/matches/player/${playerId}`,this.envUrl.urlAddress))
      .pipe(map(results => results as PlayerMatchStatsResponse[]));
  }
//
  // from the id get the tournaments stats and also the match stats
  getPlayerGrandTotalsByEventNo(eventNo, playerId): Observable<PlayerTierResponse[]> {
    return this.http
      .get(this.createCompleteRoute(`api/event/${eventNo}/stats/total/player/${playerId}`,this.envUrl.urlAddress))
      .pipe(map(results => results as PlayerTierResponse[]));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  private handleError(error: any): Promise<any> {
    console.log(error);
    return Promise.reject(error.message || error);
  }

}
