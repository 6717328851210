import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../_services';
import {LoginRequest} from '../_models/user.model';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  headerImage: string;
  headerHeader: string;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  loginForm: FormGroup;
  user: LoginRequest = new LoginRequest();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,private _snackBar: MatSnackBar) {

    this.headerImage = '/assets/images/header-volleyball.jpg';
    this.headerHeader = 'Enter Email To Log In To Fantasy AVP <span>VolleyBall</span>';

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.user.usernameOrEmail = this.f.username.value;
    this.user.password = 'abc123';


    this.loading = true;
    this.authenticationService.signIn(this.user)
      .then(() => {
        this.router.navigate([this.returnUrl]);
      })
      .catch((err) => {
        this.error = err;
        this.loading = false;
        this.openSnackBar(err.error.message,'X');
      })
      .finally(() => {
        console.log('finally');
        this.loading = false;
      })
    ;

    //console.log('Submitted successfully');
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
