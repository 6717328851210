import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'fantasy-volleyball-angular';

	constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
		iconRegistry.addSvgIcon(
			'user',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-user.svg'));
		iconRegistry.addSvgIcon(
			'strategy',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-strategy.svg'));
		iconRegistry.addSvgIcon(
			'prize',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-prize.svg'));
		iconRegistry.addSvgIcon(
			'volleyball',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-volleyball.svg'));
		iconRegistry.addSvgIcon(
			'back-arrow',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-back.svg'));
		iconRegistry.addSvgIcon(
			'sort',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-sort.svg'));
		iconRegistry.addSvgIcon(
			'refresh',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-refresh.svg'));
		iconRegistry.addSvgIcon(
			'brackets',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-brackets.svg'));
		iconRegistry.addSvgIcon(
			'brackets-grid-8',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-grid-8.svg'));
		iconRegistry.addSvgIcon(
			'brackets-grid-4',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-grid-4.svg'));
		iconRegistry.addSvgIcon(
			'brackets-grid-2',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-grid-2.svg'));
		iconRegistry.addSvgIcon(
			'brackets-grid-1',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-grid-1.svg'));
		iconRegistry.addSvgIcon(
			'brackets-grid-full',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-grid-full.svg'));
		iconRegistry.addSvgIcon(
			'female',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-female.svg'));
		iconRegistry.addSvgIcon(
			'male',
			sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-male.svg'));


	}
}
