import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { SortPipe } from './sort.pipe';
import {CustomFormatterPipe} from './custom-formatter.pipe';

@NgModule({
  declarations: [SearchPipe, SortPipe,CustomFormatterPipe],
  imports: [
    CommonModule
  ],
  exports: [SortPipe, SearchPipe, CustomFormatterPipe]
})
export class PipesModule { }
