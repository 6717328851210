import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TournamentResponse} from '../_models/tournament.model';

import {ErrorHandlerService} from '../_shared/services/error-handler.service';
import {LeaderBoardResponse} from '../_models/leader-board.model';

import {AuthenticationService} from '../_services';
import {TournamentService} from '../_services/tournament.service';

import {LeaderBoardService} from '../_services/leader-board.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.scss']
})
export class LeaderBoardComponent implements OnInit {
  event: TournamentResponse = new TournamentResponse();
  scheduleNo = 5;
  headerHeader: string;
  headerText: string;
  leaderBoardResponse: LeaderBoardResponse[];
  loggedInUsersTeam: LeaderBoardResponse;
  avatarString:string;
  errorMessage:string;
  place = 0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorHandler: ErrorHandlerService,
              private authenticationService: AuthenticationService,
              private tournamentService: TournamentService,
              private leaderboardService: LeaderBoardService,
              private _snackBar: MatSnackBar) {
   
    this.headerText = 'Click on user image to see fantasy team.';


  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

//{ queryParamsHandling: 'preserve' }
  async ngOnInit() {
//calling detectchanges ensues the icons and images get updated
if (this.authenticationService.currentUserValue) {
 
  var name = this.authenticationService.currentUserValue.userName.split("@")[0];      
  this.avatarString = "https://api.adorable.io/avatars/"+name;
}


    try {
      this.route.queryParams
        .subscribe(params => {
          this.event.eventName = params['eventName'];
          this.event.scheduleNo = params['scheduleNo'];
          this.event.statusName = params['statusName'];
          this.event.status = params['status'];
          this.event.location = params['location'];
          this.event.dateRange = params['dateRange'];

        });
    }
    catch (e) {
      this.errorHandler.handleError(e);
      this.errorMessage = this.errorHandler.errorMessage;
      console.error('ERROR loadData() Fantasy Team Page', this.errorMessage);
      this.openSnackBar(this.errorMessage,'');
      this.router.navigate(['tournament']);
    }
    this.leaderBoardLoad();
    this.headerHeader = this.event.eventName +' <span>Leader Board</span>';



  }
refresh(){
    this.leaderBoardLoad();
}
  async leaderBoardLoad() {

    this.tournamentService.getAllData().subscribe(data => {


        this.event = data.filter((value => {
          return (value.scheduleNo === 3);
        }))[0];


      this.leaderboardService.getLeaderBoardByScheduleNo(this.event.scheduleNo).subscribe(res => {
        this.leaderBoardResponse=[];
        this.leaderBoardResponse = res;
        this.leaderBoardResponse.sort((a, b) => (a.grandTotalPoints > b.grandTotalPoints ? -1 : 1));
        try {
          this.authenticationService.currentUser.subscribe((user) => {
            if (user) {
              this.loggedInUsersTeam = this.leaderBoardResponse.filter((val) => {
                return (val.userId === user.userId);
              })[0];

              this.place = this.leaderBoardResponse.findIndex((i) => {
                return (i.userId === user.userId);
              });

            }
          });

        } catch (e) {
          this.openSnackBar(e.error.message,'X');
          console.log(e);
        }
       
        this.openSnackBar('Leader Board Refreshed','');

      });
    });


  }

  viewFantasyTeam(leaderBoardResponse: LeaderBoardResponse){
    if(this.event.status !=2) {
      this.router.navigate(['/fantasy-team'], {
        queryParams:
          {
            userId: leaderBoardResponse.userId,
            userName: leaderBoardResponse.userName,
            multiplier: leaderBoardResponse.avgMultiplier,
            noPlayersEliminated: leaderBoardResponse.noPlayersEliminated,
            grandTotalPoints: leaderBoardResponse.grandTotalPoints,
            eventNo: this.event.scheduleNo

          }
      });
    }else{
      this.openSnackBar('Please Wait Until The Tournament Has Started.','');
    }
  }
}

