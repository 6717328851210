import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../_services';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginRequest, SignUpRequest} from '../_models/user.model';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
loading=false;
  returnUrl: string;
	headerImage: string;
	headerHeader: string;
	headerText: string;
  user: SignUpRequest = new SignUpRequest();
	registerForm = this.formBuilder.group({
    username: ['', Validators.required],
    fullname: ['', Validators.required],
    email: ['', [
      Validators.email,
      Validators.required,
    ]],
    password: ['', Validators.required],
    confirmpassword: ['', Validators.required]
  });

	constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,

              private authenticationService: AuthenticationService,private _snackBar: MatSnackBar,private router: Router) {
		this.headerImage = '/assets/images/header-volleyball.jpg';
		this.headerHeader = 'Register to Fantasy Beach <span>VolleyBall</span>';
		this.headerText = 'Password must contain a letter and a number no spaces no special characters.';
	}

	ngOnInit() {

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
	onSubmit() {
		if(this.registerForm.invalid) {
			return;
		}

		this.user.email = this.f.email.value;
		this.user.name = this.f.fullname.value;
		this.user.password = this.f.password.value;
		this.user.username = this.f.username.value;


    this.loading = true;
		this.authenticationService.signUpUser(this.user) .then(() => {
      this.authenticationService.logout();
      this.openSnackBar('All Signed Up please login you.','X');
      this.router.navigate(['/login']);

    })
      .catch((err) => {
        console.log(err);
        this.loading = false;
        this.openSnackBar(err.error.message,'X');
      })
      .finally(() => {
        console.log('finally');
        this.loading = false;
      })
    ;



	}
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
