import { Component, OnInit , Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: 'app-bracket-details-dialog',
  templateUrl: './bracket-details-dialog.component.html',
  styleUrls: ['./bracket-details-dialog.component.scss']
})
export class BracketDetailsDialogComponent implements OnInit {

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    
    console.log('con',data);
  }

  ngOnInit() {
  }

}
