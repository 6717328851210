import { Pipe, PipeTransform } from '@angular/core';
import {Players} from '../_models/player-tier-response';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: Array<Players>, args?: any): Array<Players> {
    array = array || [];
    return array.sort(function (a, b) {
      if (a[args.property] < b[args.property]) {
        return -1 * args.order;
      } else if (a[args.property] > b[args.property]) {
        return 1 * args.order;
      } else {
        return 0;
      }
    });
  }

}
