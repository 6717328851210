import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import {
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatGridListModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule,
  MatProgressSpinnerModule, MatDialogModule,
  MatSnackBarModule,
  MatCardModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { TournamentComponent } from './tournament/tournament.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResearchDetailsComponent } from './research-details/research-details.component';
import { ResearchComponent } from './research/research.component';
import { PlayerCardComponent } from './player-card/player-card.component';
import { DraftComponent } from './draft/draft.component';
import { LeaderBoardComponent } from './leader-board/leader-board.component';
import { SimpleDialogComponent } from './_dialogs/simple-dialog/simple-dialog.component';
import {LoginGuardGuard} from './_guards/login-guard.guard';

import {DecimalPipe} from '@angular/common';
import {PipesModule} from './_pipes/pipes.module';
import {SharedModule} from './_shared/shared.module';
import {SortPipe} from './_pipes/sort.pipe';
import {SearchPipe} from './_pipes/search.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FantasyTeamComponent } from './fantasy-team/fantasy-team.component';
import { PickEmComponent } from './pick-em/pick-em.component';
import { MatchComponent } from './match/match.component';
import { SixPackComponent } from './six-pack/six-pack.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { BracketsComponent } from './brackets/brackets.component';
import { BracketDetailsDialogComponent } from './bracket-details-dialog/bracket-details-dialog.component';
import { BracketDetailsComponent } from './bracket-details/bracket-details.component';
@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		HomeComponent,
		FooterComponent,
		HeaderComponent,
		SafeHtmlPipe,
		TermsOfUseComponent,
		TournamentComponent,
		LoginComponent,
		RegisterComponent,
		ResearchDetailsComponent,
		ResearchComponent,
		PlayerCardComponent,
		DraftComponent,
		LeaderBoardComponent,
		SimpleDialogComponent,
		FantasyTeamComponent,
		PickEmComponent,
		MatchComponent,
		SixPackComponent,
		BracketsComponent,
		BracketDetailsDialogComponent,
		BracketDetailsComponent

	],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    PipesModule,
    SharedModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    FormsModule,
    DragDropModule,
    MatCardModule
  ],
	providers: [LoginGuardGuard,DecimalPipe,SortPipe,SearchPipe,{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
	bootstrap: [AppComponent],
  entryComponents:[BracketDetailsDialogComponent]
})
export class AppModule { }
