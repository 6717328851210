import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {ResearchDetailsService} from '../_services/research-details.service';
import {PlayerMatchStatsResponse} from '../_models/player-research-details.model';
import {TournamentResponse} from '../_models/tournament.model';
import {PlayerTierResponse} from '../_models/player-tier-response';
import {ErrorHandlerService} from '../_shared/services/error-handler.service';
import {TournamentService} from '../_services/tournament.service';
import {MatSnackBar} from '@angular/material';

export interface TableFirst {
	finish: number;
	seed: number;
	grandTotal: number;
	partner: string;
	attacks: number;
	kills: number;
	digs: number;
	blocks: number;
	aces: number;
	serviceErrors: number;
	fantasyPoints: number;
	tblocks:number;
	cblocks:number;
	
}
export interface TableSecond {
	bracketMatch: string;
	setsWon: number;
	attacks: number;
	kills: number;
	digs: number;
	blocks: number;
	aces: number;
	serviceErrors: number;
	tblocks:number;
	cblocks:number;
	setNo:number;
}

@Component({
	selector: 'app-research-details',
	templateUrl: './research-details.component.html',
	styleUrls: ['./research-details.component.scss']
})

export class ResearchDetailsComponent implements OnInit {
  loaded=false;
  matchStatsLoaded=false;
  playerStatsLoaded=false;
  errorMessage:string;
playerId:number;
scheduleId:number;
  matchStats: PlayerMatchStatsResponse[];
  playerTotals: PlayerTierResponse[];
  events: TournamentResponse[];
  event: TournamentResponse;

  selectedEvent:TournamentResponse;

	avatarImage: string;
	headerHeader: string;
	
	displayedColumns: string[] = [ 'result', 'seed', 'grandPointsTotal',	'partnerName', 'attacks',	'kills', 'digs', 'blocks', 'aces', 'serviceErrors', 'statsPointTotal' ];
	dataSource = new MatTableDataSource();
	displayedColumnsTable2: string[] = [ 'bracket','setsNo', 'setsWon', 'attacks',	'kills', 'digs', 'blocks', 'aces', 'serviceErrors' ];
	dataSourceTable2 =new MatTableDataSource();

	/*@ViewChild(MatSort, {static: true}) sort: MatSort;*/

	constructor(private route: ActivatedRoute,
              private researchService:ResearchDetailsService,
              private errorHandler: ErrorHandlerService,
              private tournamentService:TournamentService,
              private _snackBar: MatSnackBar) {
    try {
      this.route.queryParams
        .subscribe(params => {
          this.playerId = params['playerId'];
          this.scheduleId = 3;
          this.loadTournaments();
        });
    }
    catch (e) {
        console.log(e);
    }

	}

	loadTournaments(){
    this.openSnackBar('Loading Stats!','X');
    this.tournamentService.getAllData().subscribe(res=>{
        this.events = res as TournamentResponse[];
        this.event = new TournamentResponse();
        this.selectedEvent = new TournamentResponse();

        this.event = res.filter((value => {
          return (value.scheduleNo === Number(this.scheduleId));
        }))[0];
        this.selectedEvent = this.event;

        this.loaded=true;
        this.loadMatchStats();
        this.loadGrandTotalStats();
      },
      (error) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
        console.error('ERROR loadData() TOURNAMENT Page', this.errorMessage);
      });
  }

  loadMatchStats() {
console.log(this.scheduleId);

    this.researchService.getPlayerMatchStats(this.scheduleId, this.playerId).subscribe(
      res => {

        this.matchStats = res as PlayerMatchStatsResponse[];

        this.dataSourceTable2 = new MatTableDataSource(this.matchStats);

        this.matchStatsLoaded = true;

      },
      err => {
        console.error('load Stats Error', err);

      }
    );
  }
  loadGrandTotalStats() {



    this.researchService.getPlayerGrandTotalsByEventNo(this.scheduleId, this.playerId).subscribe(
      res => {
        this.playerTotals = res as PlayerTierResponse[];
        console.log(this.playerTotals);
        this.dataSource = new MatTableDataSource(this.playerTotals);
		if(this.playerTotals.length>0){
        	this.avatarImage = this.playerTotals[0].imageUrl;
        	this.headerHeader =  this.playerTotals[0].name;
		}
        this.playerStatsLoaded = true;
        this.openSnackBar('Loading Stats Completed!','X');
      },
      err => {
        console.error('load Stats Error', err);

      }
    );
  }

  onRefresh(){
	  this.loadTournaments();
  }

  newEventSelected(e){

    this.scheduleId = this.selectedEvent.scheduleNo;
    this.loadTournaments();
  }

	ngOnInit() {
	/*	this.dataSource.sort = this.sort;
		this.dataSourceTable2.sort = this.sort;*/
	}
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
