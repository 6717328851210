import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchDetailsService} from '../_services/research-details.service';
import {ErrorHandlerService} from '../_shared/services/error-handler.service';
import {TournamentService} from '../_services/tournament.service';
import {MatSnackBar} from '@angular/material';
import {TournamentResponse} from '../_models/tournament.model';
import {Players, PlayerTierResponse} from '../_models/player-tier-response';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-research',
	templateUrl: './research.component.html',
	styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements OnInit {

  scheduleId=0;
  loaded=false;
  loadT=false;
	headerHeader: string;
	headerText: string;
  events: TournamentResponse[];
  event: TournamentResponse;
  errorMessage:string;
  selectedEvent:TournamentResponse;
  descending = false;
  order = 1;
  column = 'seed';
  players: Players[]=[];
  playerTier: Players[];
  athleteTierList: PlayerTierResponse[];
  filterPlayers: Players[];

	constructor(private route: ActivatedRoute,
              private router: Router,
              private researchService:ResearchDetailsService,
              private errorHandler: ErrorHandlerService,
              private tournamentService:TournamentService,
              private _snackBar: MatSnackBar) {
		this.headerHeader = 'Research <strong><span>Players</span></strong>';
		this.headerText = 'Live Stats: Click on player to see match stats breakdown! <br> <span class="green">Green</span> still alive, <span class="pink">pink</span> heading home.';
    this.loadTournaments();

	}
  ascDesc(){
    this.descending = !this.descending;
    this.order = this.descending ? 1 : -1;


  }
  onSelected(a:any){

  }
  onRemoved(a:any){

  }
  // convenience getter for easy access to form fields
 
  filterAthletes(): void {
/*

    const val=this.f.searchtext.value;
    console.log(this.f.searchtext.value);
    this.players = this.filterPlayers.filter((item) => {
      if(item.partnerName){
        return item.name.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1 ||
          item.partnerName.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1;
      }else {
        return item.name.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1;
      }


    });*/

  }


  sort(sortField:any){

    this.column =  sortField.value;
    this.descending = false;
    this.order = this.descending ? 1 : -1;


  }
  onRefresh(){
    this.loadAtheletes();
  }
  loadTournaments(){

    this.tournamentService.getAllData().subscribe(res=>{
        this.events = res as TournamentResponse[];

        
      this.loadT=true;
      this.selectedEvent = this.events[0];
        
      },
      (error) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
        console.error('ERROR loadData() TOURNAMENT Page', this.errorMessage);
        this.openSnackBar(this.errorMessage,'X');
      });
  }
	ngOnInit() {

this.scheduleId = 3;
    this.openSnackBar('Loading Players!','X');
	  this.loadAtheletes();
	}
loadAtheletes(){
  try {

    this.researchService.getResearchTournamentPlayersLiveStats(this.scheduleId).subscribe(
      res => {

        this.athleteTierList = res.sort((a, b) => (a.grandPointsTotal > b.grandPointsTotal ? -1 : 1));
        this.column = 'grandPointsTotal';
        this.loaded = true;

        this.setPlayersObject();
        this.filterPlayers = this.players;

        this.loaded=true;
        this.openSnackBar('Loading Players Complete!','X');
      },
      err => {
        this.errorHandler.handleError(err);
        this.errorMessage = this.errorHandler.errorMessage;
        console.error('ERROR loadData() TOURNAMENT Page', this.errorMessage);
        this.openSnackBar(this.errorMessage,'X');

      }
    );
  } catch (err) {

    this.router.navigate(['tournament']);
  }
}
  newEventSelected(e){

    this.scheduleId = this.selectedEvent.scheduleNo;
    this.loadAtheletes();

  }

  setPlayersObject(){
    this.players=[];
    // Between the users team and the players in tournament create a object for draft
    for(let play of this.athleteTierList){
      const player = new Players();
      player.tier = play.tier;
      player.seedMultiplier = play.seedMultiplier;
      if(this.scheduleId==0){
        player.overAllFantasyPoints = play.overAllFantasyPoints;
      }else {
        player.overAllFantasyPoints = play.grandPointsTotal;
      }
      player.matchesPlayed = play.matchesPlayed;
      player.serviceErrors = play.serviceErrors;
      player.blocks = play.blocks;
      player.tblocks = play.tblocks;
      player.cblocks=play.cblocks;
      player.digs = play.digs;
      player.aces = play.aces;
      player.kills = play.kills;
      player.playerId = play.playerId;
      player.draftLink = '';
      if(play.matchesLost>1){
        player.isOnTeam = false;

        player.color = 'pink';
      }else{
        player.color='green';
      }

      player.imageUrl = play.imageUrl;
      player.seed =play.seed;
      player.gender = play.gender;
      player.name=play.name;
      player.partnerName=play.partnerName;
      player.result =play.result;
      player.seed = play.seed;

      this.players.push(player);
    }



  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
