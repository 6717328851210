import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentUrlService} from '../_shared/services/environment-url.service';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {TournamentResponse} from '../_models/tournament.model';
import {UserPickEmSelect, UserPickMatchTeam} from '../_models/user.pickem.selections';
import {UserFantasyTeamResponse} from '../_models/player-tier-response';

@Injectable({
  providedIn: 'root'
})
export class PickEmService {
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  saveMatchPick(data:UserPickEmSelect){
    console.log('Add saveMatchPick to Fantasy Team:', data);

    return new Promise((resolve, reject) => {
      this.http
        .post(this.createCompleteRoute(`api/PickMatch/add`,this.envUrl.urlAddress), JSON.stringify(data), {
          headers: new HttpHeaders({
            Authorization: '',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          })
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    }).catch(this.handleError);
  }

  getMatchesFromAVP(matchNo){
    //https://volleyball.web4data.co.uk:8082/api/matches/
    //43
    //http://localhost:5000/api/PickMatch/add
    
    //console.log(this.createCompleteRoute(matchNo, 'https://volleyball.web4data.co.uk:8082/api/matches'));
    return this.http.get(this.createCompleteRoute(matchNo, 'https://volleyball.web4data.co.uk:8082/api/matches'),{responseType: 'text'});
  }

  //http://localhost:5000/api/PickMatch/all
  getAVPMatchesFromDataBase(competitionId){
    return this.http.get(this.createCompleteRoute(competitionId, `http://localhost:5000/api/PickMatch/all`));;
  }


  getUserPickMatchTeam(eventNo, userId): Observable<UserPickMatchTeam[]> {
    return this.http.get(this.createCompleteRoute(`api/PickMatch/event/${eventNo}/user/${userId}`,this.envUrl.urlAddress)).pipe(
      map(results => results as UserPickMatchTeam[]));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  private handleError(error: any): Promise<any> {
    console.log(error);
    return Promise.reject(error.message || error);
  }
}
