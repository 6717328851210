export class UserPickEmSelect{
  userId:number;
  matchNo:number;
  eventNo:number;
  seed:number;
  gender:string;
  competitionId:number;
}

export class UserPickMatchTeam
{
  userId:number;
  matchNo:number;
  eventNo:number;
  seed:number;
  gender:string;
  competitionId:number;
  pickedWinner:string;
  winningSeed:number;
  pickedSeed:number;
  points:number;
}