import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EnvironmentUrlService } from '../_shared/services/environment-url.service';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Observable } from 'rxjs';


import austin from '../../assets/2019_data/austin_2019.json';
import chicago from '../../assets/2019_data/chicago_2019.json';
import hawaii from '../../assets/2019_data/hawaii_2019.json';
import hermosa from '../../assets/2019_data/hermosa_2019.json';
import huntington from '../../assets/2019_data/huntington_2019.json';
import manhattan from '../../assets/2019_data/manhattan_2019.json';
import new_york from '../../assets/2019_data/new_york_2019.json';
import seattle from '../../assets/2019_data/seattle_2019.json';



@Injectable({
  providedIn: 'root'
})
export class BracketService {

  public city_bracket: Array<any> = []; //this bracket only gets updated to set the all the empty rounds except 1
  public live_bracket: Array<any>[]; //use this bracket on front end after all rounds are set 

  public bracket_size: number;
  public winner_rounds: number;
  public loser_rounds: number;
  public has_bye: boolean = false;
  private bye_seeds: Array<any> = [];
  public isEventLive: boolean = false;
  public reset_bracket: boolean = true;


  httpOptions = {
    headers: new HttpHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  //'../../assets/2019_data/'


  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  private handleError(error: any): Promise<any> {
    console.log(error);
    return Promise.reject(error.message || error);
  }


  public getBracket(name: string) {
    this.live_bracket = [];

    switch (name) {
      case 'austin':
        this.has_bye = false;
        this.bye_seeds=[];
        this.bracket_size = 16;
        this.winner_rounds = 5;
        this.loser_rounds = 4;
        this.city_bracket = austin;
        break;
      case 'chicago':
        this.has_bye = true;
        this.bye_seeds=[1,2,3,4,5,6,7,8];
        this.bracket_size = 24;
        this.winner_rounds = 6;
        this.loser_rounds = 5;
        this.city_bracket = chicago;
        break;
      case 'hawaii':
        this.has_bye = false;
        this.bye_seeds=[];
        this.bracket_size = 16;
        this.winner_rounds = 5;
        this.loser_rounds = 4;
        this.city_bracket = hawaii;
        console.log('bracketsize',this.bracket_size);
        break;
      case 'hermosa':
        this.has_bye = true;
        this.bye_seeds=[1,2,3,4,5,6,7,8];
        this.bracket_size = 24;
        this.winner_rounds = 6;
        this.loser_rounds = 5;
        this.city_bracket = hermosa;
        break;
      case 'huntington':        
        this.bracket_size = 24;
        this.winner_rounds = 6;
        this.loser_rounds = 5;
        this.city_bracket = huntington;
        this.has_bye = true;
        this.bye_seeds = [1, 2, 3, 4, 5, 6, 7, 8];
        break;
      case 'manhattan':
        this.has_bye = false;
        this.bye_seeds=[];
        this.bracket_size = 32;
        this.winner_rounds = 6;
        this.loser_rounds = 5;
        this.city_bracket = manhattan;
        break;
      case 'new_york':
        this.has_bye = false;
        this.bye_seeds=[];
        this.bracket_size = 16;
        this.winner_rounds = 5;
        this.loser_rounds = 4;
        this.city_bracket = new_york;
        break;
      case 'seattle':
        this.has_bye = false;
        this.bye_seeds=[];
        this.bracket_size = 16;
        this.winner_rounds = 5;
        this.loser_rounds = 4;
        this.city_bracket = seattle;
        break;
      //set tournament after setting tournament gind out the amount of rounds
    }
  }


  public setContendersBracket() {
    for (var a = 1; a <= this.loser_rounds; a++) {
      let rounds = this.bracketProperties[0].contenders[this.bracket_size.toString()][("round" + a).toString()];
      console.log('rounds', rounds);
      //use city bracket to get all of the matches for this round
      let matchesPerRound = this.city_bracket.filter(function (element) {
        return rounds.includes(element.MatchNo);
      });
      this.live_bracket.push(matchesPerRound);
    }
  }

  public resetBracket() {
    if (this.reset_bracket) {
      
      let round1 = this.bracketProperties[0].winners[this.bracket_size.toString()][("round1").toString()];
      let round2 = this.bracketProperties[0].winners[this.bracket_size.toString()][("round2").toString()];


      for (var a = 0; a <= this.city_bracket.length - 1; a++) {

        if (this.bracket_size > 16) {

          //check if the tournament has byes
          if (this.has_bye) {
            //if it has byes do not clear out 1st round
            if (!round1.includes(this.city_bracket[a].MatchNo)) {
              //only do this logic in second round

              if (round2.includes(this.city_bracket[a].MatchNo)) {

                //check if team has a bye if they do do not clear out them
                if (this.bye_seeds.indexOf(this.city_bracket[a].TeamA.Seed) >= 0) {
                  this.city_bracket[a].TeamB.Captain.LastName = "";
                  this.city_bracket[a].TeamB.Player.LastName = "";
                  this.city_bracket[a].TeamB.Seed = 0;
                }
                if (this.bye_seeds.indexOf(this.city_bracket[a].TeamB.Seed) >= 0) {
                  this.city_bracket[a].TeamA.Captain.LastName = this.city_bracket[a].TeamB.Captain.LastName;
                  this.city_bracket[a].TeamA.Seed = this.city_bracket[a].TeamB.Seed;
                  this.city_bracket[a].TeamA.Player.LastName = this.city_bracket[a].TeamB.Player.LastName;
                  //this is so every winner in winner bracket is in the a place holder
                  this.city_bracket[a].TeamB.Captain.LastName = "";
                  this.city_bracket[a].TeamB.Player.LastName = "";
                  this.city_bracket[a].TeamB.Seed = 0;
                }

              } else {
                this.city_bracket[a].TeamA.Captain.LastName = "";
                this.city_bracket[a].TeamA.Seed = 0;
                this.city_bracket[a].TeamA.Player.LastName = "";
                this.city_bracket[a].TeamB.Captain.LastName = "";
                this.city_bracket[a].TeamB.Player.LastName = "";
                this.city_bracket[a].TeamB.Seed = 0;
              }



            }

          } else {
            if (!round1.includes(this.city_bracket[a].MatchNo)) {
              this.city_bracket[a].TeamA.Captain.LastName = "";
              this.city_bracket[a].TeamA.Seed = 0;
              this.city_bracket[a].TeamA.Player.LastName = "";

              this.city_bracket[a].TeamB.Captain.LastName = "";
              this.city_bracket[a].TeamB.Player.LastName = "";
              this.city_bracket[a].TeamB.Seed = 0;
            }
          }



        } else {
          if (!round1.includes(this.city_bracket[a].MatchNo)) {
            this.city_bracket[a].TeamA.Captain.LastName = "";
            this.city_bracket[a].TeamA.Seed = 0;
            this.city_bracket[a].TeamA.Player.LastName = "";

            this.city_bracket[a].TeamB.Captain.LastName = "";
            this.city_bracket[a].TeamB.Player.LastName = "";
            this.city_bracket[a].TeamB.Seed = 0;
          }
        }
      }
    }
  }
  //find out the number of rounds to get
  public setWinnersBracket() {

    for (var a = 1; a <= this.winner_rounds; a++) {

      //set matches teams to nothing in order to clear our bracket before user can pick
      //this shouldnt matter cause when we go live it should be empty anyways
      //only do this for round 1 of winners keep all others get cleared out


      let rounds = this.bracketProperties[0].winners[this.bracket_size.toString()][("round" + a).toString()];
      //use city bracket to get all of the matches for this round
      let matchesPerRound = this.city_bracket.filter(function (element) {
        return rounds.includes(element.MatchNo);
      });
      this.live_bracket.push(matchesPerRound);
    }
  }

  private isUserPicksSet() {

  }



  //get Tournament by selection (id or name)
  //get Bracketology Tournament - drafting is open for this tournament by selection ()

  //to get the bracket size of each tournament
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/22 - austin  ds=16
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/26 - seattle ds=16
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/32 - chicago ds=24
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/34 - hawaii  ds=16 
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/28 - hermosa ds=24
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/20 - huntington ds=24
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/30 - manhattan ds=32
  //https://volleyball.web4data.co.uk:8082/api/competitions/bytournament/24 - new york ds=16
  //Create a component for each bracket size

  public bracketProperties: Array<any> = [{
    "winners": {
      "16": {
        "tournamentNumbers":         [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 27, 28, 29],
        "tournamentMovementLoserTo": ['18_a', '18_b', '17_a', '17_b', '16_a', '16_b', '15_a', '15_b', '20_a', '19_a', '22_a', '21_a', '26_a', '25_a', '0_a', '0_a', '0_a'],
        "tournamentMovementWinnersTo": ['9_a', '9_b', '10_a', '10_b', '11_a', '11_b', '12_a', '12_b', '13_a', '13_b', '14_a', '14_b', '27_a', '28_a', '29_a', '29_b', '1'],
        "tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25, 26, 0],
        "columnBreakIndex": [7, 11, 13, 15, 16],
        "round1": [1, 2, 3, 4, 5, 6, 7, 8],
        "round2": [9, 10, 11, 12],
        "round3": [13, 14],
        "round4": [27, 28],
        "round5": [29]
      },
      "24": {
        "tournamentNumbers":              [1,     2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 43, 44, 45],
        "tournamentMovementLoserTo": ['23_a', '24_a', '25_a', '26_a', '27_a', '28_a', '29_a', '30_a', '30_b', '29_b', '28_b', '27_b', '26_b', '25_b', '24_b', '23_b', '36_a', '35_a', '38_a', '37_a', '42_a', '41_a', '3', '3', '2'],
        "tournamentMovementWinnersTo": ['9_b', '10_b', '11_b', '12_b', '13_b', '14_b', '15_b', '16_b', '17_a', '17_b', '18_a', '18_b', '19_a', '19_b', '20_a', '20_b', '21_a', '21_b', '22_a', '22_b', '43_a', '44_a', '45_a', '45_b', '1'],
        "tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 42, 0],
        "columnBreakIndex": [7, 15, 19, 21, 23, 24],
        "round1": [1, 2, 3, 4, 5, 6, 7, 8],
        "round2": [9, 10, 11, 12, 13, 14, 15, 16],
        "round3": [17, 18, 19, 20],
        "round4": [21, 22],
        "round5": [43, 44],
        "round6": [45]
      },
      "32": {
        "tournamentNumbers":               [1,      2,      3,      4,      5,      6,      7,      8,      9,     10,     11,     12,     13,     14,     15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 59, 60, 61],
        "tournamentMovementWinnersTo": ['17_a', '17_b', '18_a', '18_b', '19_a', '19_b', '20_a', '20_b', '21_a', '21_b', '22_a', '22_b', '23_a', '23_b', '24_a', '24_b', '25_a', '25_b', '26_a', '26_b', '27_a', '27_b', '28_a', '28_b', '29_a', '29_b', '30_a', '30_b', '59_a', '60_a', '61_a', '61_b', '1'],
        "tournamentMovementLoserTo": ['31_a', '31_b', '32_a', '32_b', '33_a', '33_b', '34_a', '34_b', '35_a', '35_b', '36_a', '36_b', '37_a', '37_b', '38_a', '38_b', '46_a', '45_a', '44_a', '43_a', '42_a', '41_a', '40_a', '39_a', '52_a', '51_a', '54_a', '53_a', '58_a', '57_a', '57_b', '58_a', 0],
        "tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 57, 58, 0],
        "columnBreakIndex": [15, 23, 27, 29, 31, 32],
        "round1": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        "round2": [17, 18, 19, 20, 21, 22, 23, 24],
        "round3": [25, 26, 27, 28],
        "round4": [29, 30],
        "round5": [59, 60],
        "round6": [61]
      }
    },
    "contenders": {
      "16": {
        "tournamentNumbers":            [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
        "tournamentMovementWinnersTo": ['19_b', '20_b', '21_b', '22_b', '23_a', '23_b', '24_a', '24_b', '25_b', '26_b', '27_b', '28_b'],
        "tournamentMovementFrom": [0, 0, 0, 0, 10, 9, 12, 11, 0, 0, 14, 13],
        "columnBreakIndex": [3, 7, 9, 11],
        "round1": [15, 16, 17, 18],
        "round2": [19, 20, 21, 22],
        "round3": [23, 24],
        "round4": [25, 26]
      },
      "24": {
        "tournamentNumbers": [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
        "tournamentMovementWinnersTo": ['31_a', '31_b', '32_a', '32_b', '33_a', '33_b', '34_a', '34_b', '35_b', '36_b', '37_b', '38_b', '39_a', '39_b', '40_a', '40_b', '41_b', '42_b', '43_b', '44_b'],
        "tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 18, 17, 20, 19, 0, 0, 22, 21],
        "columnBreakIndex": [7, 11, 15, 17, 19],
        "round1": [23, 24, 25, 26, 27, 28, 29, 30],
        "round2": [31, 32, 33, 34],
        "round3": [35, 36, 37, 38],
        "round4": [39, 40],
        "round5": [41, 42]
      },
      "32": {
        "tournamentNumbers": [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58],
        "tournamentMovementWinnersTo": ['39_b', '40_b', '41_b', '42_b', '43_b', '44_b', '45_b', '46_b', '47_a', '47_b', '48_a', '48_b', '49_a', '49_b', '50_a', '50_b', '51_b', '52_b', '53_b', '54_b', '55_a', '55_b', '56_a', '56_b', '57_b', '58_b', '59_b', '60_b'],
        "tournamentMovementFrom": [0, 0, 0, 0, 0, 0, 0, 0, 24, 23, 22, 21, 20, 19, 18, 17, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 29],
        "columnBreakIndex": [7, 15, 19, 23, 25, 27],
        "round1": [31, 32, 33, 34, 35, 36, 37, 38],
        "round2": [39, 40, 41, 42, 43, 44, 45, 46],
        "round3": [47, 48, 49, 50],
        "round4": [51, 52, 53, 54],
        "round5": [55, 56],
        "round6": [57, 58]
      }
    }
  }];
}
