import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from '../../environments';
import {LoginRequest, SignUpRequest} from '../_models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private httpOptions = {
    headers: new HttpHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  signUpUser(data:SignUpRequest){
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.urlAddress}/api/auth/signup`, JSON.stringify(data), this.httpOptions)
        .subscribe(
          res => {
            localStorage.setItem('currentUser', JSON.stringify(res));
            this.currentUserSubject.next(res);
            resolve(res);
          },
          err => {
            reject(err);
          }
        )
    });
  }

  signIn(data: LoginRequest) {
    //console.log('signupUser:', data);

    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.urlAddress}/api/auth/signin`, JSON.stringify(data), this.httpOptions)
        .subscribe(
          res => {
            localStorage.setItem('currentUser', JSON.stringify(res));
            this.currentUserSubject.next(res);
            resolve(res);
          },
          err => {
            reject(err);
          }
        )
    });
  }
  logout() {
    // remove user from local storage and set current user to null
    localStorage.clear();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  private handleError(error: any): Promise<any> {
    console.log(error);
    return Promise.reject(error.message || error);
  }

}
