import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UserPickEmSelect} from '../_models/user.pickem.selections';
import {Players} from '../_models/player-tier-response';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss']
})
export class MatchComponent implements OnInit {
  @Input() color: string;
  @Input() matchState: string;
  @Input() matchNo: number;
  @Input() bracket: string;
  @Input() teamA:any;
  @Input() teamB:any;
  @Input() matchSchedule:any;
  @Input() sets:any;
  @Input() winner:number;
  @Input() selectedSeed:number;
  @Input() compId:number;
  teamBSeed;
  teamASeed;

  @Output() selectedTeam = new EventEmitter<any>();


  constructor( private router: Router) {

  }
    see(e,t){
        t.compId=this.compId;
        t.matchNo = this.matchNo;

        if(e==='teamASeed'){
          this.teamBSeed = false;
        }
      if(e==='teamBSeed'){
        this.teamASeed = false;
      }

      this.selectedMatchTeam(t);

    }

  selectedMatchTeam(selTeam: any) {
    this.selectedTeam.emit(selTeam);
  }


  ngOnInit() {

   if(this.selectedSeed===this.teamA.Seed){
      this.teamASeed = true;
      this.teamBSeed = false;
    }
    if(this.selectedSeed===this.teamB.Seed){
      this.teamBSeed = true;
      this.teamASeed = false;
    }
  }

}
