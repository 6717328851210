import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {


  transform(items: any[], name: string): any[] {
    if (!items) {return [];
    }
    if (!name) {return items;
    }
    name = name.toLowerCase();
    return items.filter(it => {
      return it.name.toLowerCase().includes(name); // only filter  name
    });
  }
}
