import { Component, OnInit } from '@angular/core';
import {PickEmService} from '../_services/pick-em.service';
import {UserPickEmSelect, UserPickMatchTeam} from '../_models/user.pickem.selections';
import {Players} from '../_models/player-tier-response';
import {AuthenticationService} from '../_services';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-pick-em',
  templateUrl: './pick-em.component.html',
  styleUrls: ['./pick-em.component.scss']
})
export class PickEmComponent implements OnInit {
  headerHeader: string;
  headerText: string;
  totalPickMatchPoints=0;
  userId:any=0;
  matches: any;
  matchesSaturday:any;
  matchesSunday:any;
  loaded:boolean;
  friday=true;
  saturday=false;
  sunday=false;
  selected:any=[];
  allMATCHES:any;
  userPickTeamSelect:UserPickEmSelect;
  pickemSelectionList:UserPickEmSelect[];

  userPickMatchTeam:UserPickMatchTeam[];

  constructor(private service: PickEmService,
              private authenticationService: AuthenticationService,
              private _snackBar: MatSnackBar,) {
    this.userId= this.authenticationService.currentUserValue.userId;
    this.loadUserPickMatchTeam('?ms=F','all');
    setInterval(() => this.loadUserPickMatchTeam('?ms=F','all'),5000);
    

  }

  dayClick(day){
    if(day=='friday'){
      this.friday=true;
      this.saturday=false;
      this.sunday=false;
    }
    if(day=='saturday'){
      this.friday=false;
      this.saturday=true;
      this.sunday=false;
    }if(day=='sunday'){
      this.friday=false;
      this.saturday=false;
      this.sunday=true;
    }
  }




  isFriday( matchno, index, array){
    
    if(matchno.MatchSchedule.ScheduleDisp.indexOf('Fri')>-1){
      return true;
    }else{
      return false;
    }
  }
  isSaturday( matchno, index, array){  
    if(matchno.MatchSchedule.ScheduleDisp.indexOf('Sat')>-1){
      return true;
    }else{
      return false;
    }
  }
  isSunday( matchno, index, array){

    if(matchno.MatchSchedule.ScheduleDisp.indexOf('Sun')>-1){
      return true;
    }else{
      return false;
    }
  }

///sort by date
     GFG_sortFunction(a, b) {
      var dateA = new Date(a.MatchSchedule.ScheduleTime).getTime();
      var dateB = new Date(b.MatchSchedule.ScheduleTime).getTime();
      return dateA > dateB ? 1 : -1;
    }


    show_winners_bracket(matchno, index, array){
      if(matchno.Bracket.indexOf('Winner')>-1){
        return true;
      }else{
        return false;
      }
    }
    show_Contender_bracket(matchno, index, array){
      if(matchno.Bracket.indexOf('Contender')>-1){
        return true;
      }
      else{
        return false;
      }
    }


    show_odd_match_numbers(matchno, index, array){
      if(matchno.MatchNo & 1)
      {
        //odd

        return true;
      }
      else
      {
        return false;
        // EVEN
      }
    }
    show_even_match_numbers(matchno, index, array){
      if(matchno.MatchNo & 1)
      {
        return false;
        // ODD
      }
      else
      {

        return true;
        // EVEN
      }
    }



  test_sort(sortField:any){
    if(sortField.value == 'winner'){
      this.loadUserPickMatchTeam('?ms=F','winner');
    }
    if(sortField.value== 'contender') {
      this.loadUserPickMatchTeam('?ms=F','contender');
    }

    if(sortField.value=='all'){
      this.loadUserPickMatchTeam('?ms=F','all');
    }

  }
  sort(sortField:any){
    this.loadUserPickMatchTeam(sortField.value,'all');
  }

  //its fine to show all matches on the screen no need to seperate by days
  //just add sort functions to sort the array on more then one thing
  //unplayed matches to pick from ; finished matches to see how you did ;all ;
  //sort by match time ; sort by match no : sort by contenders winners
  //For right now its just working for huntington beach
  loadUserPickMatchTeam(match_status,sortOddEven){
    this.userId= this.authenticationService.currentUserValue.userId;  // console.log(this.userId);
    this.service.getAVPMatchesFromDataBase(27).subscribe((data:any)=> {
      this.service.getUserPickMatchTeam(1, this.userId).subscribe((val) => {
        this.userPickMatchTeam = val;
        const sum = this.userPickMatchTeam.filter((item) => item.points).reduce((sum, current) => sum + current.points, 0);
        this.totalPickMatchPoints = sum;
       // this.headerText = 'Total Pick-Em Points : ' + this.totalPickMatchPoints;
        this.headerText = 'Total Pick-Em Points : ' + this.totalPickMatchPoints;

        for (let match of data) {
          const index = this.userPickMatchTeam.findIndex(x => x.matchNo === match.MatchNo && x.competitionId === match.CompetitionId);
          if (index == -1) {
          } else {
            const pick = this.userPickMatchTeam.find(x => x.matchNo === match.MatchNo && x.competitionId === match.CompetitionId);
            data.find(x => x.MatchNo === match.MatchNo && x.CompetitionId === match.CompetitionId).SelectedSeed = pick.pickedSeed;
          }
        }
      if(sortOddEven== 'all') {
        this.matches = data.filter(this.isFriday).sort(this.GFG_sortFunction);
        this.matchesSaturday = data.filter(this.isSaturday).sort(this.GFG_sortFunction);
      }
      if(sortOddEven=='contender'){
        this.matches = data.filter(this.isFriday).sort(this.GFG_sortFunction).filter(this.show_Contender_bracket);
        this.matchesSaturday = data.filter(this.isSaturday).sort(this.GFG_sortFunction).filter(this.show_Contender_bracket);
      }
      if(sortOddEven=='winner'){
        this.matches = data.filter(this.isFriday).sort(this.GFG_sortFunction).filter(this.show_winners_bracket);
        this.matchesSaturday = data.filter(this.isSaturday).sort(this.GFG_sortFunction).filter(this.show_winners_bracket);
      }

      this.matchesSunday = data.filter(this.isSunday).sort(this.GFG_sortFunction);

     
      
      this.loaded = true;



        this.loaded = true;
      });
    });

  }

  saveMatchTeam(team:any){

    this.userPickTeamSelect = new UserPickEmSelect();
    this.userPickTeamSelect.competitionId =team.compId;
    this.userPickTeamSelect.seed= team.Seed;
    this.userPickTeamSelect.matchNo = team.matchNo;
    this.userPickTeamSelect.eventNo =1;
    this.userPickTeamSelect.gender = team.Captain.Gender;
    this.userPickTeamSelect.userId=this.userId;


    this.service.saveMatchPick(this.userPickTeamSelect).then((data)=>{
      this.openSnackBar('Team Saved Good Luck!','X');
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit() {

  }

}
