import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {Players, PlayerTierResponse, UserFantasyTeamResponse} from '../_models/player-tier-response';
import {TournamentResponse} from '../_models/tournament.model';
import {Router} from '@angular/router';

@Component({
	selector: 'app-player-card',
	templateUrl: './player-card.component.html',
	styleUrls: ['./player-card.component.scss']
})
export class PlayerCardComponent implements OnInit {

  @Input() isDraft: boolean;
  @Input() draftLink: string;
	@Input() color: string;
	@Input() pointMultiplier: number;
  @Input() pointTotal: number;
  @Input() matchesPlayed: number;
  @Input() serviceErrors: number;
  @Input() blocks: number;
  @Input() cblocks:number;
  @Input() tblocks:number;
  @Input() digs: number;
  @Input() aces: number;
  @Input() kills: number;
  @Input() playerId: number;
  @Input() isOnTeam: boolean;
  @Input() playerImage: string;
  @Input() seed: number;
  @Input() gender: string;
  @Input() name: string;
  @Input() partnerName: string;
  @Input() player: Players;
  @Input() result:number;

  @Output() selectedPlayer = new EventEmitter<Players>();
  @Output() removePlayer = new EventEmitter<Players>();

	constructor(       private router: Router) { }

	ngOnInit() {


	}

  selectedPlayerToTeam(selPlayer: Players) {
	  //do logic if player is on team already
      this.selectedPlayer.emit(selPlayer);


  }
  removePlayerFromTeam(selPlayer: Players) {
	  //do logic
    this.removePlayer.emit(selPlayer);

  }

  openResearchPlayer(n:Players){
    this.router.navigate(['/research-details'], {queryParams:
        { playerId: n.playerId,
          scheduleNo: 1,


        }});
  }
}
