import { Component, OnInit } from '@angular/core';
import {TournamentResponse} from '../_models/tournament.model';

import {ErrorHandlerService} from '../_shared/services/error-handler.service';
import {NavigationExtras, Router} from '@angular/router';
import {TournamentService} from '../_services/tournament.service';


@Component({
	selector: 'app-tournament',
	templateUrl: './tournament.component.html',
	styleUrls: ['./tournament.component.scss']
})
export class TournamentComponent implements OnInit {
  events: TournamentResponse[];
  errorMessage: string;

	headerImage: string;
	headerHeader: string;
	headerText: string;

	constructor( private router: Router,
               private service: TournamentService,
              private errorHandler: ErrorHandlerService) {
	
		this.headerHeader = 'AVP Beach Volleyball <span>Champion Cup Series 2020</span>';
		service.clearCache();
    this.loadData();
	}

	ngOnInit() {
	  this.loadData();
	}
  // https://www.djamware.com/post/5b94bb1d80aca74669894415/ionic-4-angular-6-tutorial-call-multiple-services-at-once
  async loadData() {


	  this.service.getAllData().subscribe(res=>{
      this.events = res as TournamentResponse[];
      
    },
      (error) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
        console.error('ERROR loadData() TOURNAMENT Page', this.errorMessage);
      });



  }

  open(n: TournamentResponse) {

//https://alligator.io/angular/query-parameters/
    if(n.status ===1){
      return;
    }
    if (n.status === 2) {
      this.router.navigate(['/draft'],
        {queryParams:
            { dateRange: n.dateRange,
              location: n.location,
              status: n.status,
              scheduleNo: n.scheduleNo,
              statusName: n.statusName,
              eventName: n.eventName

            }});
    } else {
      this.router.navigate(['/leader-board'], {queryParams:
          { dateRange: n.dateRange,
            location: n.location,
            status: n.status,
            scheduleNo: n.scheduleNo,
            statusName: n.statusName,
            eventName: n.eventName

          }});
    }
  }

  trackByFn(index, item) {
    return item.scheduleNo; // unique id corresponding to the item
  }
}
