import { Component, OnInit, AfterViewChecked, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import {TournamentResponse} from '../_models/tournament.model';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../_services';
import { Route } from '@angular/compiler/src/core';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterContentChecked,AfterViewChecked,AfterViewInit {
  logoutlogin;
  avatarString:string;
  constructor(private router: Router,private authenticationService:AuthenticationService,private cd: ChangeDetectorRef){
    
  }
  ngAfterViewInit() {
    
   
  }
  ngAfterViewChecked() {
   
   
  }
  ngAfterContentChecked() {
    //calling detectchanges ensues the icons and images get updated
    if (this.authenticationService.currentUserValue) {
      this.logoutlogin = 'Log Out';
      var name = this.authenticationService.currentUserValue.userName.split("@")[0];      
      this.avatarString = "https://api.adorable.io/avatars/"+name;
    }
    else {
      this.logoutlogin = 'Log In';
      this.avatarString=null;
    }
    this.cd.detectChanges();
  }
	ngOnInit() {
    if (this.authenticationService.currentUserValue) {
      this.logoutlogin = 'Log Out';
      var name = this.authenticationService.currentUserValue.userName.split("@")[0];      
      this.avatarString = "https://api.adorable.io/avatars/"+name;
    }
    else {
      this.logoutlogin = 'Log In';
      this.avatarString=null;
    }
	}
 logout(){
  this.avatarString=null;
     this.authenticationService.logout();
     this.router.navigate(['/login']);

 }
}
