export interface TournamentModel {
  dateRange: string;
  location: string;
  status: number;
  scheduleNo: number;
}
export class TournamentResponse {
  dateRange: string;
  location: string;
  status: number;
  scheduleNo: number;
  statusName: string;
  eventName: string;
}
