import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../_services';
import {MatDialog, MatSnackBar} from '@angular/material';
import {SimpleDialogComponent} from '../_dialogs/simple-dialog/simple-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService,private router: Router,public dialog: MatDialog,private _snackBar: MatSnackBar){}
  async canActivate(): Promise<boolean> {
    // by adding await it waits until storage is returned
    const hasSeen = await this.authenticationService.currentUserValue;

    if (!hasSeen) {

      this.openSnackBar('Please Login','X');
      this.router.navigate(['/login']);
      /* const dialogRef = this.dialog.open(SimpleDialogComponent, {

      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('Please Login');
        console.log('The dialog was closed');
        
      }); */
      return false;
    }

    return true;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
