import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentUrlService} from '../_shared/services/environment-url.service';
import {Observable} from 'rxjs';
import {LeaderBoardResponse} from '../_models/leader-board.model';
import { map } from 'rxjs/operators';
import {PlayerTierResponse} from '../_models/player-tier-response';
@Injectable({
  providedIn: 'root'
})
export class LeaderBoardService {
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {
  }

  getLeaderBoardByScheduleNo(scheduleNo): Observable<LeaderBoardResponse[]> {
    return this.http.get(this.createCompleteRoute(`api/event/${scheduleNo}/leaderBoard/`,this.envUrl.urlAddress)).pipe(map(results => results as LeaderBoardResponse[]));
  }

  //
  getLeaderBoardUsersFantasyTeam(eventNo, userId): Observable<PlayerTierResponse[]> {
    return this.http.get(this.createCompleteRoute(`api/event/${eventNo}/user/${userId}/team`,this.envUrl.urlAddress)).pipe(
      map(results => results as PlayerTierResponse[]));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }
}
