import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from '../_services';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.ShadowDom
})
export class HeaderComponent implements OnInit {

	@Input() image: string;
	@Input() avatar: string;
	@Input() header: string;
	@Input() text: string;
	@Input() smallerMargin: boolean;
	@Input() backArrow: boolean;
	headerStyles: object;

	constructor(
		private location: Location,private auth: AuthenticationService,
	) {

	}

	ngOnInit() {
		if (this.image) {
			this.headerStyles = {
				backgroundImage: 'url("' + this.image + '")'
			}
		}
		
	}

	goBack(): void {
		this.location.back();
	}

}
