import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services';
import {TournamentResponse} from '../_models/tournament.model';
import {TournamentService} from '../_services/tournament.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DraftService} from '../_services/draft.service';
import {Players, PlayerTierResponse, UserFantasyTeamRequest, UserFantasyTeamResponse} from '../_models/player-tier-response';

@Component({
	selector: 'app-draft',
	templateUrl: './draft.component.html',
	styleUrls: ['./draft.component.scss']

})
export class DraftComponent implements OnInit {
  event: TournamentResponse = new TournamentResponse();
	headerHeader: string;
	headerText:string;
  tierSelected: any = 1;
  players: Players[]=[];
  playerTier: Players[];
  athleteTier: PlayerTierResponse[];
  athleteTierList: PlayerTierResponse[];
  userTeam: UserFantasyTeamResponse[];
  userId: any = 0;

  tier1Count: any = 0;
  tier2Count: any = 0;
  tier3Count: any = 0;
  athleteFantasy: UserFantasyTeamRequest = new UserFantasyTeamRequest();

  descending = false;
  order: number = 1;
  column = 'seed';
  loaded=false;

	constructor(private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private tournamentService:TournamentService,
              private _snackBar: MatSnackBar,
              private draftService:DraftService
              ) {

    try {
      this.route.queryParams
        .subscribe(params => {
          this.event.eventName = params['eventName'];
          this.event.scheduleNo = params['scheduleNo'];
          this.event.statusName = params['statusName'];
          this.event.status = params['status'];
          this.event.location = params['location'];
          this.event.dateRange = params['dateRange'];
        });
        console.log(this.event);
    }
    catch (e) {
      console.error(e);
      this.tournamentService.getAllData().subscribe(data => {
        this.event = data.filter((value => {
          return (value.scheduleNo === 3);
        }))[0];
      });
    }
    
    this.headerHeader = '<span>'+this.event.eventName+'</span> Draft Board';
    this.headerText='Picks Are Saved After Clicking on the +/-.<br />Click on user image to view detailed match stats.';
    this.userId= this.authenticationService.currentUserValue.userId;
	}

  ascDesc(){
    this.descending = !this.descending;
    this.order = this.descending ? 1 : -1;


  }
  sort(sortField:any){

    this.column =  sortField.value;
    this.descending = false;
    this.order = this.descending ? 1 : -1;


  }

	ngOnInit() {


    this.tournamentService.getAllData().subscribe(data => {
      this.event = data.filter((value => {
        return (value.scheduleNo ==3);
      }))[0];

      this.loadFantasyTeam(1);
    });
	}

	async loadFantasyTeam(tier){
	  this.openSnackBar('Loading Fantasy Team','');
   this.userId= this.authenticationService.currentUserValue.userId;
    this.draftService.getTournamentPageUsersFantasyTeam(this.event.scheduleNo, this.userId).subscribe(
      res => {

        this.userTeam = res;

        this.setTeamTierCount();
        this.loadAthletes(tier);

      },
      err => {
        this.openSnackBar(err.error.message,'X');
        console.log('getuserfantasyerror', err);

      }
    );


  }

  onSelected(player: Players) {

	  if(player.tier ===1){
	    //4 players
      if (this.userTeam.filter((value: UserFantasyTeamResponse) => value.tier === player.tier).length === 2) {
        this.openSnackBar('Only 2 Players Per Tier In Tier 1','X');
      }else {
        const index = this.userTeam.findIndex(x => x.playerId === player.playerId);
        if (index === -1) {
          this.addToTeam(player);
        } else {
          this.openSnackBar('Nice Try you already have that player', 'X');
        }
      }
    }
	  if(player.tier===2){
	    //4 players
      if (this.userTeam.filter((value: UserFantasyTeamResponse) => value.tier === player.tier).length === 4) {
        this.openSnackBar('Only 4 Players Per Tier In Tier 2','X');
      }else {
        const index = this.userTeam.findIndex(x => x.playerId === player.playerId);
        if (index === -1) {
          this.addToTeam(player);
        } else {
          this.openSnackBar('Nice Try you already have that player', 'X');
        }
      }
    }
	  if(player.tier===3){
	    //2 players
      if (this.userTeam.filter((value: UserFantasyTeamResponse) => value.tier === player.tier).length === 2) {
        this.openSnackBar('Only 2 Players Per Tier In Tier 3','X');
      }else {
        const index = this.userTeam.findIndex(x => x.playerId === player.playerId);
        if (index === -1) {
          this.addToTeam(player);
        } else {
          this.openSnackBar('Nice Try you already have that player', 'X');
        }
      }
    }

  }

  onRemoved(player: Players) {
   this.removeFromTeam(player);
  }

  async addToTeam(player: Players) {
	  console.log(player);

    this.openSnackBar('Saving player to your team','');
    // check to see if you already took his or her partner for this tournament

    try {
      this.athleteFantasy = new UserFantasyTeamRequest();

      this.athleteFantasy.playerId = player.playerId;
      this.athleteFantasy.scheduleNo = this.event.scheduleNo;
      this.athleteFantasy.tier = player.tier;
      this.athleteFantasy.userId = this.userId;


      this.draftService
        .addAthleteToTeam(this.athleteFantasy)
        .then(res => {

          const userFantasyTeamResponse = new UserFantasyTeamResponse();
          userFantasyTeamResponse.imageUrl = player.imageUrl;
          userFantasyTeamResponse.name = player.name;
          userFantasyTeamResponse.playerId = player.playerId;
          userFantasyTeamResponse.scheduleNo = this.event.scheduleNo;
          userFantasyTeamResponse.tier = player.tier;
          userFantasyTeamResponse.userId = this.userId;

          this.userTeam.push(userFantasyTeamResponse);
          this.setTeamTierCount();
          this.loadFantasyTeam(player.tier);
          this.openSnackBar(player.name + ' Saved successfully','X');
        })
        .catch(err => {
          console.log('Error in Add Team', err);
          this.openSnackBar('TRY ERROR IN ADD TEAM','X');
          this.router.navigate(['app/tournaments']);
        });
    } catch (ex) {
      console.log('ERROR adding player', ex);

      this.router.navigate(['app/tournaments']);
    }


  }

  async removeFromTeam(player: Players) {



    try {
      this.athleteFantasy = new UserFantasyTeamRequest();
      this.athleteFantasy.playerId = player.playerId;
      this.athleteFantasy.scheduleNo = this.event.scheduleNo;
      this.athleteFantasy.tier = player.tier;
      this.athleteFantasy.userId = this.userId;

      this.draftService
        .removeAthleteFromTeam(this.athleteFantasy)
        .then(res => {
          const removeUserFantasyTeamResponse = new UserFantasyTeamResponse();
          removeUserFantasyTeamResponse.imageUrl = player.imageUrl;
          removeUserFantasyTeamResponse.name = player.name;
          removeUserFantasyTeamResponse.playerId = player.playerId;
          removeUserFantasyTeamResponse.scheduleNo = this.event.scheduleNo;
          removeUserFantasyTeamResponse.tier = player.tier;
          removeUserFantasyTeamResponse.userId = this.userId;


          const index = this.userTeam.indexOf(removeUserFantasyTeamResponse);
          this.userTeam.splice(index, 1);
          this.setTeamTierCount();
          this.loadFantasyTeam(player.tier);
          this.openSnackBar(player.name + ' Removed Successfully','X');
        })
        .catch(err => {
          console.log('Error in Add Team', err);

          this.router.navigate(['app/tournaments']);
        });
    } catch (ex) {
      console.log('TRY ERROR IN REMOVE', ex);
      this.openSnackBar('TRY ERROR IN REMOVE','X');
      this.router.navigate(['app/tournaments']);
    }
  }

  setTeamTierCount() {
    if (this.userTeam) {
      this.tier1Count = this.userTeam.filter((value: UserFantasyTeamResponse) => value.tier === 1).length;
      this.tier2Count = this.userTeam.filter((value: UserFantasyTeamResponse) => value.tier === 2).length;
      this.tier3Count = this.userTeam.filter((value: UserFantasyTeamResponse) => value.tier === 3).length;

    }
  }

  segmentChanged(event) {
    this.tierSelected = Number(event);
    this.playerTier = this.players.filter((value: Players) => value.tier === this.tierSelected);    
    this.headerText='Draft<span>Tier' + event +' </span>';
    this.headerHeader = 'Draft Players For '+this.event.eventName;
  }

  async loadAthletes(t) {

    try {
      this.draftService.getTournamentPlayersByEvent(this.event.scheduleNo).subscribe(
        res => {

          this.athleteTierList = res;

          this.tierSelected = t;
          this.setPlayersObject();
          this.playerTier = this.players.filter((value: Players) => value.tier === t);
          this.loaded=true;
        },
        err => {
          console.log(err);
          this.openSnackBar(err.error.message,'X');
        }
      );
    } catch (err) {
      this.openSnackBar(err.error.message,'X');
      this.router.navigate(['tournament']);
    }
  }


  setPlayersObject(){
	  this.players=[];
    // Between the users team and the players in tournament create a object for draft
    for(let play of this.athleteTierList){
      
            const player = new Players();
      player.tier = play.tier;
      player.seedMultiplier = play.seedMultiplier;
      player.overAllFantasyPoints = play.overAllFantasyPoints;
      player.matchesPlayed = play.matchesPlayed;
      player.serviceErrors = play.serviceErrors;
      player.blocks = play.blocks;
      player.digs = play.digs;
      player.aces = play.aces;
      player.kills = play.kills;
      player.playerId = play.playerId;
      player.tblocks = play.tblocks;
      player.cblocks=play.cblocks;
      const index = this.userTeam.findIndex(x => x.playerId === player.playerId);
      if (index === -1){
        player.isOnTeam = false;
        player.draftLink = 'add';
        player.color = 'green';
      }
      else{
        player.isOnTeam = true;
        player.draftLink ='remove';
        player.color = 'pink';
      }

      player.imageUrl = play.imageUrl;
      player.seed =play.seed;
      player.gender = play.gender;
      player.name=play.name;
      player.partnerName=play.partnerName;
      player.result=0;
      this.players.push(player);
    }



  }




  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
