import { Component, OnInit } from '@angular/core';
import {Players, PlayerTierResponse} from '../_models/player-tier-response';
import {ActivatedRoute, Router} from '@angular/router';

import {ErrorHandlerService} from '../_shared/services/error-handler.service';

import {MatSnackBar} from '@angular/material';
import {AuthenticationService} from '../_services';
import {LeaderBoardService} from '../_services/leader-board.service';

@Component({
  selector: 'app-fantasy-team',
  templateUrl: './fantasy-team.component.html',
  styleUrls: ['./fantasy-team.component.scss']
})
export class FantasyTeamComponent implements OnInit {
  headerHeader: string;
  headerText: string;
 loaded:boolean;
  descending = false;
  order = 1;
  column = 'seed';
  players: Players[]=[];
  playerTier: Players[];
  athleteTierList: PlayerTierResponse[];
  errorMessage;

  userId:number;
  userName:string;
  multiplier:string;
  noPlayersEliminated:number;
  grandTotalPoints:number;
  eventNo:number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorHandler: ErrorHandlerService,
              private leaderBoardService:LeaderBoardService,
              private _snackBar: MatSnackBar,
              private authenticationService: AuthenticationService) {
    this.headerHeader = 'Fantasy Team';
    this.headerText = 'Refreshing This Page Will Calculate Real-Time Stats For your Players! <br> <span class="green">0</span> /8, <span class="pink">0</span> Total Points.';


  }

  ngOnInit() {
    try {
      this.route.queryParams
        .subscribe(params => {
          this.userId = params['userId'];
          this.userName =  params['userName'];
          this.multiplier = params['multiplier'];
          this.noPlayersEliminated =  params['noPlayersEliminated'];
          this.grandTotalPoints = params['grandTotalPoints'];
          this.eventNo = params['eventNo'];
          this.headerHeader = '<span>' +this.userName + '</span>';
          let remainOnTeam=8-this.noPlayersEliminated;
          this.headerText = '<span class="green">'+ Number(this.grandTotalPoints).toFixed(2) +'</span> , <span class="pink">' + remainOnTeam + '</span>/8 , <span class="pink">' + Number(this.multiplier).toFixed(2)  +'</span> Avg Multiplier.';
          this.loadAtheletes();
          this.loaded=true;
          //load team
        });
    }
    catch (e) {
      this.errorHandler.handleError(e);
      this.errorMessage = this.errorHandler.errorMessage;
      console.error('ERROR loadData() Fantasy Team Page', this.errorMessage);
      this.openSnackBar(this.errorMessage,'');
      this.router.navigate(['leader-board']);
    }
  }

  ascDesc(){
    this.descending = !this.descending;
    this.order = this.descending ? 1 : -1;
  }

  sort(sortField:any){
    this.column =  sortField.value;
    this.descending = false;
    this.order = this.descending ? 1 : -1;
  }

  loadTeam(){

  }
  loadAtheletes(){
    try {

      this.leaderBoardService.getLeaderBoardUsersFantasyTeam(this.eventNo, this.userId).subscribe(
        res => {

          this.athleteTierList = res.sort((a, b) => (a.grandPointsTotal > b.grandPointsTotal ? -1 : 1));
          this.column = 'grandPointsTotal';
          this.setPlayersObject();
          console.log(this.players);

          this.openSnackBar('Loading Players Complete!','');
        },
        err => {
          this.errorHandler.handleError(err);
          this.errorMessage = this.errorHandler.errorMessage;
          console.error('ERROR loadData() Fantasy Team Page', this.errorMessage);
          this.openSnackBar(this.errorMessage,'');

        }
      );
    } catch (err) {
      this.errorHandler.handleError(err);
      this.errorMessage = this.errorHandler.errorMessage;
      console.error('ERROR loadData() Fantasy Team Page', this.errorMessage);
      this.openSnackBar(this.errorMessage,'');
      this.router.navigate(['leader-board']);
    }
  }
  setPlayersObject(){
    this.players=[];
    // Between the users team and the players in tournament create a object for draft
    for(let play of this.athleteTierList){
      const player = new Players();
      player.tier = play.tier;
      player.seedMultiplier = play.seedMultiplier;
      player.overAllFantasyPoints = play.grandPointsTotal;
      player.matchesPlayed = play.matchesPlayed;
      player.serviceErrors = play.serviceErrors;
      player.blocks = play.blocks;
      player.tblocks = play.tblocks;
      player.cblocks=play.cblocks;
      player.digs = play.digs;
      player.aces = play.aces;
      player.kills = play.kills;
      player.playerId = play.playerId;
      player.draftLink = '';
      player.isOnTeam = true;
      if(play.matchesLost>1){
        player.color = 'pink';
      }else{
        player.color='green';
      }
      player.imageUrl = play.imageUrl;
      player.seed =play.seed;
      player.gender = play.gender;
      player.name=play.name;
      player.partnerName=play.partnerName;
      player.result =play.result;
      player.seed = play.seed;

      this.players.push(player);
    }
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onRefresh(){this.loadAtheletes()}
  onSelected(a:any){  }
  onRemoved(a:any){  }
}
