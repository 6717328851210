import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentUrlService} from '../_shared/services/environment-url.service';
import {Observable} from 'rxjs';
import {PlayerTierResponse, UserFantasyTeamRequest, UserFantasyTeamResponse} from '../_models/player-tier-response';
import {map} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DraftService {
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  getTournamentPageUsersFantasyTeam(eventNo, userId): Observable<UserFantasyTeamResponse[]> {
    return this.http.get(this.createCompleteRoute(`api/event/${eventNo}/tournament-player/user/${userId}`,this.envUrl.urlAddress)).pipe(
      map(results => results as UserFantasyTeamResponse[]));
  }
  getTournamentPlayersByEvent(eventNo: number): Observable<PlayerTierResponse[]> {
    return this.http.get(this.createCompleteRoute(`api/event/${eventNo}/all/players/`,this.envUrl.urlAddress)).pipe(map(results => results as PlayerTierResponse[]));
  }
  removeAthleteFromTeam(data: UserFantasyTeamRequest) {
    console.log('Remove Athlete From Fantasy Team:', data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      data
    };

    return new Promise((resolve, reject) => {

      // tslint:disable-next-line:max-line-length
      this.http.delete( this.createCompleteRoute(`api/fantasyTeam/delete/u/${data.userId}/p/${data.playerId}/s/${data.scheduleNo}/t/${data.tier}`,this.envUrl.urlAddress), options).subscribe(
        s => {
          resolve(s);
        },
        err => {
          reject(err);
        }
      );
    }).catch(this.handleError);
  }

  addAthleteToTeam(data: UserFantasyTeamRequest) {
    console.log('Add Athlete to Fantasy Team:', data);

    return new Promise((resolve, reject) => {
      this.http
        .post(this.createCompleteRoute(`api/fantasyTeam/add`,this.envUrl.urlAddress), JSON.stringify(data), {
          headers: new HttpHeaders({
            Authorization: '',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          })
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    }).catch(this.handleError);
  }
  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  private handleError(error: any): Promise<any> {
    console.log(error);
    return Promise.reject(error.message || error);
  }
}
